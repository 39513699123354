import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  json,
} from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from '@remix-run/react';

import stylesheet from '~/styles.css?url';
import Sidebar from './components/sidebar';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap',
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png',
  },

  { rel: 'manifest', href: '/site.webmanifest' },
];

export const meta: MetaFunction = () => {
  return [{ title: 'DDMA' }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const production = process.env.NODE_ENV === 'production';
  const url = new URL(request.url);
  if (
    url.pathname.startsWith('/auth') ||
    url.pathname.startsWith('/questionnaires/user') ||
    url.pathname.includes('/responses')
  ) {
    return json({ showSidebar: false, production });
  }
  return json({ showSidebar: true, production });
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const showSidebar = data?.showSidebar ?? false;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        {!data?.production && (
          <div className="bg-red-600 border border-red-700 text-white p-2 select-none left-1/2 -translate-x-1/2 text-center fixed">
            TESTOMGEVING
          </div>
        )}
        {showSidebar ? (
          <div className="h-full flex">
            <Sidebar />
            <div className="flex flex-1 flex-col h-dvh">{children}</div>
          </div>
        ) : (
          children
        )}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}
